import { createAction, props } from '@ngrx/store';
import { IEstimateData } from '../../features/commons/reservation-details/change-date-estimate/change-date-estimate.component';
import { ChangeCustomerCompanyRequest } from '../../features/commons/reservation-details/models/change-customer-company.request';
import { IAddRoomRequest } from '../../features/commons/reservation-details/models/reservation-add-rooms';
import { IReservationLog } from '../../features/commons/reservation-details/models/reservation-log.model';
import { ReservationPriceChangeRequest } from '../../features/commons/reservation-details/models/reservation-price-change-request';
import { ReservationWarning } from '../../features/commons/reservation-details/models/reservation-warning';
import { ICustomerReservationAttach } from '../../features/commons/reservation-details/models/reservation.guests.model';
import { UpdateReservationAccommodationMoreRequest } from '../../features/commons/reservation-details/models/update-reservation-accommodation-more.request';
import { Hook, Hooks } from '../../helpers/effect-hooks';
import {
  CreateScaConfirmationRequestRequest,
  DailyUpdateRequest,
  IShowCreditCard,
  IShowCreditCardRequest,
  ISplitReservation,
  IStatusUpdate,
  ReservationTotals,
  ScaConfirmationRequest,
  SetHousekeeperRecordRequest,
  SetHousekeeperRecordUsersRequest,
  UpdateCityTaxReportExclusionRequest,
  UpdateReservationKeepAccommodationRequest,
} from '../../models';
import {
  UpdateReservationCheckinCheckoutRequest,
  UpdateReservationCheckinCheckoutRequestData,
} from '../../models/requests/update-reservation-checkin-checkout.request';
import { IUpdateReservationChekinOutDateRequest } from '../../models/requests/update-reservation-checkin-out-date-request';

/**
 * L'azione loadRequest richiama il dettaglio della prenotazione
 */
export const loadRequest = createAction(
  '[Reservation Details Custom v2] Load Request',
  props<{ reservationId: number }>(),
);
export const loadSuccess = createAction(
  '[Reservation Details Custom v2] Load Success',
  props<{
    reservation: any;
    channelName: string;
    propertyName: string;
    currencySymbol: string;
    currencyCode?: string;
    languageName: string;
    languageIsoCode: string;
  }>(),
);
export const loadFailure = createAction(
  '[Reservation Details Custom v2] Load Failure',
  props<{ error: any }>(),
);

/**
 * L'azione updateCheckInOutRequest modifica orario di checkin stato ecc della prenotazione [SUMMARY][OK]
 */
export const updateCheckInOutRequest = createAction(
  '[Reservation Details Custom v2] Update Checkin Checkout Request',
  props<UpdateReservationCheckinCheckoutRequest>(),
);
export const updateCheckInOutSuccess = createAction(
  '[Reservation Details Custom v2] Update Checkin Checkout Success',
  props<{
    checkinStatus: UpdateReservationCheckinCheckoutRequestData;
    reservation_id: number;
    tabIndex: number;
  }>(),
);
export const updateCheckInOutFailure = createAction(
  '[Reservation Details Custom v2] Update Checkin Checkout Failure',
  props<{ error: any }>(),
);

/**
 * L'azione updateNoteRequest modifica le note della prenotazione [SUMMARY][OK]
 */
export const updateNoteRequest = createAction(
  '[Reservation Details Custom v2] Update Note Request',
  props<{
    reservationId: number;
    noteData: string;
    reservation_accommodation_id: number;
    typeNote: string;
    tabIndex: number;
  }>(),
);
export const updateNoteSuccess = createAction(
  '[Reservation Details Custom v2] Update Note Success',
  props<{
    noteData: string;
    typeNote: string;
    tabIndex: number;
  }>(),
);
export const updateNoteFailure = createAction(
  '[Reservation Details Custom v2] Update Note Failure',
  props<{ error: any }>(),
);

/**
 * L'azione updateCheckinCheckoutDateRequest modifica le date della prenotazione [SUMMARY][OK]
 */
export const updateCheckinCheckoutDateRequest = createAction(
  '[Reservation Details Custom v2] Update Checkin Checkout Date Request',
  props<IUpdateReservationChekinOutDateRequest>(),
);
export const updateCheckinCheckoutDateSuccess = createAction(
  '[Reservation Details Custom v2] Update Checkin Checkout Date Success',
);
export const updateCheckinCheckoutDateFailure = createAction(
  '[Reservation Details Custom v2] Update Checkin Checkout Date Failure',
  props<{ error: any; openModalData?: any }>(),
);
export const updateCheckinCheckoutDateWarnings = createAction(
  '[Reservation Details Custom v2] Update Checkin Checkout Date Warnings',
  props<{
    payload: IUpdateReservationChekinOutDateRequest;
    newData: IEstimateData;
  }>(),
);

/**
 * L'azione updatePaymentMethodRequest modifica il metodo di pagamento della prenotazione [SUMMARY][OK]
 */
export const updatePaymentMethodRequest = createAction(
  '[Reservation Details Custom v2] Update Payment Method Request',
  props<{
    reservationId: number;
    data: any;
  }>(),
);
export const updatePaymentMethodSuccess = createAction(
  '[Reservation Details Custom v2] Update Payment Method Success',
  props<{ changeOnlyPayment?: boolean; payment_method_id: number }>(),
);
export const updatePaymentMethodFailure = createAction(
  '[Reservation Details Custom v2] Update Payment Method Failure',
  props<{ error: any }>(),
);

/**
 * L'azione updateStatusRequest modifica lo stato della prenotazione [SUMMARY][OK]
 */
export const updateStatusRequest = createAction(
  '[Reservation Details Custom v2] Update Status Request',
  props<{
    data: IStatusUpdate;
  }>(),
);
export const updateStatusSuccess = createAction(
  '[Reservation Details Custom v2] Update Status Success',
  props<{
    tabIndex: number;
    status: string;
    expiration_date?: Date | string;
    availability_option?: 0 | 1;
  }>(),
);
export const updateStatusFailure = createAction(
  '[Reservation Details Custom v2] Update Status Failure',
  props<{ error: any }>(),
);

/**
 * L'azione updateReservationDetailsRequest modifica i dettagli prenotazione [SUMMARY][OK]
 */
export const updateReservationDetailsRequest = createAction(
  '[Reservation Details Custom v2] Update Reservation Details Request',
  props<{
    reservationId: number;
    reservationData: any;
  }>(),
);
export const updateReservationDetailsSuccess = createAction(
  '[Reservation Details Custom v2] Update Reservation Details Success',
  props<{
    reservationData: any;
  }>(),
);
export const updateReservationDetailsFailure = createAction(
  '[Reservation Details Custom v2] Update Reservation Details Failure',
  props<{ error: any }>(),
);

/**
 * L'azione loadMediaRequest carica le immagini delle camere della prenotazione [SUMMARY][OK]
 */
export const loadMediaRequest = createAction(
  '[Reservation Details Custom v2] Load Media Request',
  props<{
    accommodationIds: number[];
  }>(),
);
export const loadMediaSuccess = createAction(
  '[Reservation Details Custom v2] Load Media Success',
  props<{
    medias: { [accommodationId: string]: string };
  }>(),
);
export const loadMediaFailure = createAction(
  '[Reservation Details Custom v2] Load Media Failure',
  props<{ error: any }>(),
);

/**
 * L'azione updateCustomerRequest modifica i dettagli del customer della  prenotazione [SUMMARY][ok]
 */
export const updateCustomerRequest = createAction(
  '[Reservation Details Custom v2] Update Customer Request',
  props<{
    reservationId: number;
    customerData: any;
    tabIndex: number;
  }>(),
);
export const updateCustomerSuccess = createAction(
  '[Reservation Details Custom v2] Update Customer Success',
  props<{
    tabIndex: number;
    customerData: any;
  }>(),
);
export const updateCustomerFailure = createAction(
  '[Reservation Details Custom v2] Update Customer Failure',
  props<{ error: any }>(),
);

/**
 * L'azione splitReservationRequest serve per il cambio camera-rateplan-trattamento della prenotazione [SUMMARY][OK]
 */
export const splitReservationRequest = createAction(
  '[Reservation Details Custom v2] Split Reservation Request',
  props<{
    data: ISplitReservation;
    onSuccess?: () => void;
    onFailure?: () => void;
  }>(),
);
export const splitReservationSuccess = createAction(
  '[Reservation Details Custom v2] Split Reservation Success',
);
export const splitReservationFailure = createAction(
  '[Reservation Details Custom v2] Split Reservation Failure',
  props<{ error: any }>(),
);

/**
 * L'azione setKeepAvailabilityRequest serve per set non riprisinare disponibilità in caso di cancellazione prenotazione [SUMMARY][OK]
 */
export const setKeepAvailabilityRequest = createAction(
  '[Reservation Details Custom v2] Set keep Availability Request',
  props<{
    keep_availability: boolean;
    reservation_id: number;
  }>(),
);
export const setKeepAvailabilitySuccess = createAction(
  '[Reservation Details Custom v2] Set keep Availability Success',
  props<{
    keep_availability: boolean;
  }>(),
);

export const setKeepAvailabilityFailure = createAction(
  '[Reservation Details Custom v2] Set keep Availability Failure',
  props<{ error: any }>(),
);

/**
 * L'azione loadLogRequest serve per caricare i dati dei log della prenotazione [LOGS][OK]
 */

export const loadLogsRequest = createAction(
  '[Reservation Details Custom v2] Load Logs Request',
  props<{
    reservationId: number;
  }>(),
);
export const loadLogsSuccess = createAction(
  '[Reservation Details Custom v2] Load Logs Success',
  props<{
    logsData: IReservationLog[];
  }>(),
);

export const loadLogsFailure = createAction(
  '[Reservation Details Custom v2] Load Logs Failure',
  props<{ error: any }>(),
);

/**
 * L'azione loadWarningsRequest serve per caricare i warnings associati alla prenotazione
 */

export const loadWarningsRequest = createAction(
  '[Reservation Details Custom v2] Load Warnings Request',
  props<{
    reservationId: number;
  }>(),
);
export const loadWarningsSuccess = createAction(
  '[Reservation Details Custom v2] Load Warnings Success',
  props<{
    warnings: ReservationWarning[];
  }>(),
);
export const loadWarningsFailure = createAction(
  '[Reservation Details Custom v2] Load Warnings Failure',
  props<{ error: any }>(),
);

/**
 * L'azione loadTotals richiama i totals della prenotazione
 */
export const loadTotalsRequest = createAction(
  '[Reservation Details Custom v2] Load Totals Request',
  props<{ reservationId: number }>(),
);
export const loadTotalsSuccess = createAction(
  '[Reservation Details Custom v2] Load Totals Success',
  props<{
    reservationTotals: ReservationTotals;
  }>(),
);
export const loadTotalsFailure = createAction(
  '[Reservation Details Custom v2] Load Totals Failure',
  props<{ error: any }>(),
);

/**
 * Attach guest to reservation
 */
export const attachGuestToReservationRequest = createAction(
  '[Reservation Details Custom v2] Attach Guest To Reservation Request',
  props<{
    data: ICustomerReservationAttach;
    actionType: 'edit' | 'new' | 'delete';
    force_operation?: boolean;
    hooks?: Hooks;
  }>(),
);
export const attachGuestToReservationSuccess = createAction(
  '[Reservation Details Custom v2] Attach Guest To Reservation Success',
  props<{ data: ICustomerReservationAttach }>(),
);
export const attachGuestToReservationFailure = createAction(
  '[Reservation Details Custom v2] Attach Guest To Reservation Failure',
  props<{ error: any }>(),
);
export const detachGuestToReservationRequest = createAction(
  '[Reservation Details Custom v2] Detach Guest To Reservation Request',
  props<{
    data: ICustomerReservationAttach;
    force_operation?: boolean;
    onlyAttach?: boolean;
  }>(),
);
export const detachGuestToReservationSuccess = createAction(
  '[Reservation Details Custom v2] Detach Guest To Reservation Success',
  props<{ data: ICustomerReservationAttach; onlyAttach?: boolean }>(),
);
export const detachGuestToReservationFailure = createAction(
  '[Reservation Details Custom v2] Detach Guest To Reservation Failure',
  props<{ error: any }>(),
);

/**
 * Set checkin/checkout of a reservation guest
 */
export const setGuestCheckinCheckoutRequest = createAction(
  '[Reservation Details Custom v2] Set Guest Checkin Checkout Request',
  props<{
    res_acc_room_guest_id: number[];
    checkin: boolean;
    checkout: boolean;
  }>(),
);
export const setGuestCheckinCheckoutSuccess = createAction(
  '[Reservation Details Custom v2] Set Guest Checkin Checkout Success',
);
export const setGuestCheckinCheckoutFailure = createAction(
  '[Reservation Details Custom v2] Set Guest Checkin Checkout Failure',
  props<{ error: any }>(),
);

/**
 * Add reservation accommodation
 */
export const addReservationAccommodationRequest = createAction(
  '[Reservation Details Custom v2] Add Reservation Accommodation Request',
  props<{
    data: IAddRoomRequest;
    onSuccess?: Hook;
    onFailure?: Hook;
  }>(),
);
export const addReservationAccommodationSuccess = createAction(
  '[Reservation Details Custom v2] Add Reservation Accommodation Success',
  props<{ accommodation: { roomreservation_id: string } }>(),
);
export const addReservationAccommodationFailure = createAction(
  '[Reservation Details Custom v2] Add Reservation Accommodation Failure',
  props<{ error: any }>(),
);

/**
 * Change price request
 */
export const changePriceRequest = createAction(
  '[Reservation Details Custom v2] Change Price Request',
  props<{
    data: ReservationPriceChangeRequest;
  }>(),
);
export const changePriceSuccess = createAction(
  '[Reservation Details Custom v2] Change Price Success',
);
export const changePriceFailure = createAction(
  '[Reservation Details Custom v2] Change Price Failure',
  props<{ error: any }>(),
);

/**
 * Daily update request
 */
export const dailyUpdateRequest = createAction(
  '[Reservation Details Custom v2] Daily Update Request',
  props<{
    data: DailyUpdateRequest[];
    onSuccess?: () => void;
    onFailure?: () => void;
  }>(),
);
export const dailyUpdateSuccess = createAction(
  '[Reservation Details Custom v2] Daily Update Success',
);
export const dailyUpdateFailure = createAction(
  '[Reservation Details Custom v2] Daily Update Failure',
  props<{ error: any }>(),
);

/**
 * Richiama il dettaglio della carta di credito associata alla prenotazione
 */
export const loadCreditCardRequest = createAction(
  '[Reservation Details Custom v2] Load Credit Card Request',
  props<{
    data: IShowCreditCardRequest;
  }>(),
);
export const loadCreditCardSuccess = createAction(
  '[Reservation Details Custom v2] Load Credit Card Success',
  props<{ data: IShowCreditCard }>(),
);
export const loadCreditCardFailure = createAction(
  '[Reservation Details Custom v2] Load Credit Card Failure',
  props<{ error: any }>(),
);

export const resetCreditCard = createAction(
  '[Reservation Details Custom v2] Reset Credit Card',
);

/**
 * Calcola tassa di soggiorno
 */
export const calculateCityTaxRequest = createAction(
  '[Reservation Details Custom v2] Calculate City Tax Request',
  props<{ reservationId: number }>(),
);

export const calculateCityTaxSuccess = createAction(
  '[Reservation Details Custom v2] Calculate City Tax Success',
);

export const calculateCityTaxFailure = createAction(
  '[Reservation Details Custom v2] Calculate City Tax Failure',
  props<{ error: any }>(),
);

/**
 * Abilita disabilita spostamento camera
 */
export const setKeepAccommodationRequest = createAction(
  '[Reservation Details Custom v2] Set Keep Accommodation Request',
  props<UpdateReservationKeepAccommodationRequest>(),
);
export const setKeepAccommodationSuccess = createAction(
  '[Reservation Details Custom v2] Set Keep Accommodation Success',
  props<UpdateReservationKeepAccommodationRequest>(),
);
export const setKeepAccommodationFailure = createAction(
  '[Reservation Details Custom v2] Set Keep Accommodation Failure',
  props<{ error: any }>(),
);

/**
 * Abilita disabilita spostamento camera su tutte le camere della prenotazione
 */
export const setEntireReservationsKeepAccommodationRequest = createAction(
  '[Reservation Details Custom v2] Set Entire Reservation Keep Accommodation Request',
  props<
    Omit<UpdateReservationKeepAccommodationRequest, 'roomreservation_id'>
  >(),
);
export const setEntireReservationsKeepAccommodationSuccess = createAction(
  '[Reservation Details Custom v2] Set Entire Reservation Keep Accommodation Success',
  props<{
    keep_accommodation: boolean;
  }>(),
);
export const setEntireReservationsKeepAccommodationFailure = createAction(
  '[Reservation Details Custom v2] Set Entire Reservation Keep Accommodation Failure',
  props<{ error: any }>(),
);

/**
 * Impostazione dell'esclusione della camera dal report della tassa di soggiorno
 */
export const setCityTaxReportExclusionRequest = createAction(
  '[Reservation Details Custom v2] Set City Tax Report Exclusion Request',
  props<UpdateCityTaxReportExclusionRequest>(),
);
export const setCityTaxReportExclusionSuccess = createAction(
  '[Reservation Details Custom v2] Set City Tax Report Exclusion Success',
  props<UpdateCityTaxReportExclusionRequest>(),
);
export const setCityTaxReportExclusionFailure = createAction(
  '[Reservation Details Custom v2] Set City Tax Report Exclusion Failure',
  props<{ error: any }>(),
);

/**
 * SCA
 */
export const createScaConfirmationRequestRequest = createAction(
  '[Reservation Details Custom v2] Create Sca Confirmation Request',
  props<{ request: CreateScaConfirmationRequestRequest }>(),
);

export const createScaConfirmationRequestSuccess = createAction(
  '[Reservation Details Custom v2] Create Sca Confirmation Success',
  props<{
    scaConfirmationRequest: ScaConfirmationRequest;
  }>(),
);

export const createScaConfirmationRequestFailure = createAction(
  '[Reservation Details Custom v2] Create Sca Confirmation Failure',
  props<{ error: any }>(),
);

/**
 * Reset tassa di soggiorno
 */
export const resetCityTaxRequest = createAction(
  '[Reservation Details Custom v2] Reset City Tax Request',
  props<{ reservationId: number }>(),
);

export const resetCityTaxSuccess = createAction(
  '[Reservation Details Custom v2] Reset City Tax Success',
);

export const resetCityTaxFailure = createAction(
  '[Reservation Details Custom v2] Reset City Tax Failure',
  props<{ error: any }>(),
);

/**
 * Aggiornamento utenti governante
 */
export const setHousekeeperUsersRequest = createAction(
  '[Reservation Details Custom v2] Set Housekeeper Users Request',
  props<{
    request: SetHousekeeperRecordUsersRequest;
    tabIndex: number;
  }>(),
);
export const setHousekeeperUsersSuccess = createAction(
  '[Reservation Details Custom v2] Set Housekeeper Users Success',
  props<{
    request: SetHousekeeperRecordUsersRequest;
    tabIndex: number;
  }>(),
);
export const setHousekeeperUsersFailure = createAction(
  '[Reservation Details Custom v2] Set Housekeeper Users Failure',
  props<{ error: any }>(),
);

/**
 * Aggiornamento record governante
 */
export const setHousekeeperRecordRequest = createAction(
  '[Reservation Details Custom v2] Set Housekeeper Record Request',
  props<{
    request: Omit<SetHousekeeperRecordRequest, 'group_id'>;
    tabIndex: number;
  }>(),
);
export const setHousekeeperRecordSuccess = createAction(
  '[Reservation Details Custom v2] Set Housekeeper Record Success',
  props<{
    request: Omit<SetHousekeeperRecordRequest, 'group_id'>;
    tabIndex: number;
  }>(),
);
export const setHousekeeperRecordFailure = createAction(
  '[Reservation Details Custom v2] Set Housekeeper Record Failure',
  props<{ error: any }>(),
);

/**
 * Aggiorna il booker o la company della prenotazione
 */
export const updateBookerCompanyRequest = createAction(
  '[Reservation Details Custom v2] Update Booker-Company Request',
  props<ChangeCustomerCompanyRequest>(),
);
export const updateBookerCompanySuccess = createAction(
  '[Reservation Details Custom v2] Update Booker-Company Success',
);
export const updateBookerCompanyWarning = createAction(
  '[Reservation Details Custom v2] Update Booker-Company Warning',
  props<{
    newPrice: number;
    requestPayload: ChangeCustomerCompanyRequest;
  }>(),
);
export const updateBookerCompanyFailure = createAction(
  '[Reservation Details Custom v2] Update Booker-Company Failure',
  props<{ error: any }>(),
);

/**
 * Aggiorna eventuali chiavi del more delle accommodations
 */
export const loadReservationAccommodationMoreRequest = createAction(
  '[Reservation Details Custom v2] Load Reservation Accommodation More Request',
  props<{
    reservationID: number;
    roomReservationID?: string[];
  }>(),
);
export const loadReservationAccommodationMoreSuccess = createAction(
  '[Reservation Details Custom v2] Load Reservation Accommodation More Success',
  props<{ moreFields: { [roomReservationId: string]: Record<string, any> } }>(),
);
export const loadReservationAccommodationMoreFailure = createAction(
  '[Reservation Details Custom v2] Load Reservation Accommodation More Failure',
  props<{ error: any }>(),
);

/**
 * Aggiorna eventuali chiavi del more delle accommodations
 */
export const updateReservationAccommodationMoreRequest = createAction(
  '[Reservation Details Custom v2] Update Reservation Accommodation More Request',
  props<{
    request: UpdateReservationAccommodationMoreRequest;
    onSuccess?: Hook;
  }>(),
);
export const updateReservationAccommodationMoreSuccess = createAction(
  '[Reservation Details Custom v2] Update Reservation Accommodation More Success',
  props<{ request: UpdateReservationAccommodationMoreRequest }>(),
);
export const updateReservationAccommodationMoreFailure = createAction(
  '[Reservation Details Custom v2] Update Reservation Accommodation More Failure',
  props<{ error: any }>(),
);

export const updateReservationAccommodationBedTypeRequest = createAction(
  '[Reservation Details Custom v2] Update Reservation Accommodation Bed Type Request',
  props<{
    reservationAccommodationId: number;
    bedTypeCombinationId: number;
    tabIndex: number;
    disableNotify?: boolean;
  }>(),
);
export const updateReservationAccommodationBedTypeSuccess = createAction(
  '[Reservation Details Custom v2] Update Reservation Accommodation Bed Type Success',
);
export const updateReservationAccommodationBedTypeFailure = createAction(
  '[Reservation Details Custom v2] Update Reservation Accommodation Bed Type Failure',
  props<{ error: any }>(),
);

/**
 * L'azione updateTab permette di effettuare un merge di dati
 * all'interno della tab indicata
 */
export const updateTab = createAction(
  '[Reservation Details Custom v2] Update Tab Action',
  props<{ data: any; tabIndex: number }>(),
);

/**
 * L'azione updateTab permette di effettuare un merge di dati
 * all'interno della tab indicata
 */
export const setGeneratedBills = createAction(
  '[Reservation Details Custom v2] Set Generated Bills Action',
  props<{ generated_bills: boolean }>(),
);

/**
 * L'azione resetState accetta un parametro keys.
 * Se viene passato keys l'azione resetterà solo le chiavi specificate nell'array.
 * Se non viene passato keys l'azione resetterà tutto lo stato
 */
export const resetState = createAction(
  '[Reservation Details Custom v2] Reset State',
  props<{ keys?: string[] }>(),
);

/**
 * L'azione setExpandGuest permette di settare lo stato dei collapse presenti nella tab ospiti in modalità mobile.
 */
export const setExpandedGuest = createAction(
  '[Reservation Details Custom v2] Set Expand Guest Action',
  props<Record<number | string, boolean>>(),
);

/**
 * L'azione setGuestToscrollGuest permette di settare l'ospite del componente by-guest-card-mobile al quale approdare dopo che venono effettuate delle modifiche a quest'ultimo.
 */
export const setGuestToScrollGuest = createAction(
  '[Reservation Details Custom v2] Set Guest To Scroll Action',
  props<{ guestId: number | string }>(),
);
