import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { get } from 'lodash';
import { of } from 'rxjs';
import {
  catchError,
  exhaustMap,
  flatMap,
  map,
  mergeMap,
  switchMap,
  withLatestFrom,
} from 'rxjs/operators';

import { IResponseSuccess } from '../../core/models/response-sucess.model';
import { ReservationWarning } from '../../features/commons/reservation-details/models/reservation-warning';
import { effectHooks } from '../../helpers';
import { ScaConfirmationRequest } from '../../models';
import { AppStore } from '../../models/types/app-store';
import { HousekeeperDashboardService } from '../../services';
import { ReservationDetailsService } from '../../services/reservation-details.service';
import { WarningConfirmModalService } from '../../services/warning-confirm-modal.service';
import { NotificationService } from '../../ui/services/notification.service';

import * as fromActions from './actions';
import { HttpErrorResponse } from '@angular/common/http';
import { ErrorHandlerService } from '../../core/services/error-handler.service';

@Injectable()
export class ReservationDetailsCustomStoreEffects {
  constructor(
    private dataService: ReservationDetailsService,
    private actions$: Actions,
    private errorHandler: ErrorHandlerService,
    private store: Store<AppStore>,
    private translate: TranslateService,
    private notifications: NotificationService,
    private router: Router,
    private warningConfirmModalService: WarningConfirmModalService,
    private housekeeperDashboardService: HousekeeperDashboardService,
  ) {}
  /** Ricarichiamo solo i totali della prenotazione così da evitare di chiamare tutti i dati */
  loadTotals$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.loadTotalsRequest),
      flatMap(({ type, reservationId }) =>
        this.dataService.loadTotals(reservationId).pipe(
          map(({ data, meta }: IResponseSuccess) => {
            return fromActions.loadTotalsSuccess({
              reservationTotals: { ...data[0] },
            });
          }),
          catchError((error) => {
            this.errorHandler.handle(error);
            return of(fromActions.loadTotalsFailure(error));
          }),
        ),
      ),
    ),
  );

  /* Load Dettaglio prenotazione */
  load$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.loadRequest),
      withLatestFrom(this.store),
      switchMap(([{ reservationId }, store]) => {
        return this.dataService.load(reservationId).pipe(
          map((response: IResponseSuccess) => {
            const reservation = response.data[0];
            const reservationProperties: number[] =
              reservation.accommodations.map(({ property_id }) => +property_id);

            const propertyFound = store.core.allProperties.find(
              (property) => reservationProperties.indexOf(+property.id) !== -1,
            );
            if (!propertyFound) {
              this.notifications.push({
                title: this.translate.instant('error'),
                content: this.translate.instant('notifications.load_failure', {
                  param: this.translate.instant(
                    'reservation_of_another_property',
                  ),
                }),
                type: 'error',
              });
              this.router.navigate(['/admin']);
              return fromActions.loadFailure({
                error: new Error(),
              });
            }
            const propertyName = propertyFound.name;
            const channelName =
              store.channels.entities[reservation.channel_id].name;

            const languageFind = store.core.generics.languages.find(
              (lang) => lang.id === (reservation.booker.language_id || 1),
            );
            const languageName = languageFind.name;
            const languageIsoCode = languageFind.iso_code;
            const currencySymbol = store.core.generics.currencies.find(
              (currency) => currency.id === reservation.currency_id,
            ).symbol;
            const currencyCode = store.core.generics.currencies.find(
              (currency) => currency.id === reservation.currency_id,
            ).code;
            return fromActions.loadSuccess({
              reservation,
              channelName,
              propertyName,
              currencySymbol,
              currencyCode,
              languageName,
              languageIsoCode,
            });
          }),
          catchError((error) => {
            this.errorHandler.handle(error);
            return of(fromActions.loadTotalsFailure(error));
          }),
        );
      }),
    ),
  );
  /** aggiorniamo checkin e checkout senza reload ma aggiorniamo lo state al success */
  updateCheckInOut$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.updateCheckInOutRequest),
      exhaustMap((params) => {
        return this.dataService.updateCheckinCheckout(params).pipe(
          map((_) => {
            this.notifications.push({
              title: this.translate.instant('done'),
              content: this.translate.instant('notifications.update_success', {
                param: this.translate.instant('reservation'),
              }),
              type: 'success',
            });
            return fromActions.updateCheckInOutSuccess(params);
          }),
          catchError((error) => {
            this.errorHandler.handle(error);
            return of(fromActions.updateCheckInOutFailure(error));
          }),
        );
      }),
    ),
  );

  /** Aggiorna eventuali chiavi del more delle accommodations */
  updateReservationAccommodationMore$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.updateReservationAccommodationMoreRequest),
      exhaustMap(({ request, onSuccess }) => {
        return this.dataService
          .updateReservationAccommodationMore(request)
          .pipe(
            effectHooks({ onSuccess }),
            map((_) => {
              this.notifications.push({
                title: this.translate.instant('done'),
                content: this.translate.instant(
                  'notifications.update_success',
                  {
                    param: this.translate.instant('reservation'),
                  },
                ),
                type: 'success',
              });
              return fromActions.updateReservationAccommodationMoreSuccess({
                request,
              });
            }),
            catchError((error) => {
              this.errorHandler.handle(error);
              return of(
                fromActions.updateReservationAccommodationMoreFailure(error),
              );
            }),
          );
      }),
    ),
  );

  /** Legge le chiavi del more delle accommodations */
  loadReservationAccommodationMore$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.loadReservationAccommodationMoreRequest),
      exhaustMap(({ reservationID, roomReservationID }) => {
        return this.dataService
          .loadReservationAccommodationMore(reservationID, roomReservationID)
          .pipe(
            map(({ data: moreFields }: IResponseSuccess) => {
              return fromActions.loadReservationAccommodationMoreSuccess({
                moreFields,
              });
            }),
            catchError((error) => {
              this.errorHandler.handle(error);
              return of(
                fromActions.loadReservationAccommodationMoreFailure(error),
              );
            }),
          );
      }),
    ),
  );

  /** aggiorniamo le note senza reload ma aggiorniamo lo state al success */
  updateNote$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.updateNoteRequest),
      exhaustMap(
        ({
          reservationId,
          noteData,
          reservation_accommodation_id,
          typeNote,
          tabIndex,
        }) => {
          let typeNoteToSend = typeNote;
          if (typeNote.includes('_general')) {
            reservation_accommodation_id = null;
            typeNoteToSend = typeNoteToSend.replace('_general', '');
          }

          return this.dataService
            .updateNotes(
              reservationId,
              typeNoteToSend,
              noteData,
              reservation_accommodation_id,
            )
            .pipe(
              map((_) => {
                this.notifications.push({
                  title: this.translate.instant('done'),
                  content: this.translate.instant(
                    'notifications.update_success',
                    {
                      param: this.translate.instant('note'),
                    },
                  ),
                  type: 'success',
                });
                return fromActions.updateNoteSuccess({
                  noteData,
                  typeNote,
                  tabIndex,
                });
              }),
              catchError((error) => {
                this.errorHandler.handle(error);
                return of(fromActions.updateNoteFailure(error));
              }),
            );
        },
      ),
    ),
  );
  /** aggiorniamo il metodo di pagamento senza reload ma aggiorniamo lo state al success */
  updatePaymentMethod$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.updatePaymentMethodRequest),
      exhaustMap(({ reservationId, data }) => {
        return this.dataService.updatePaymentMethod(reservationId, data).pipe(
          switchMap((_) => {
            this.notifications.push({
              title: this.translate.instant('done'),
              content: this.translate.instant('notifications.update_success', {
                param: this.translate.instant('reservation'),
              }),
              type: 'success',
            });
            const { credit_card_holder, payment_method_id } = data;
            let changeOnlyPayment = false;
            if (credit_card_holder) {
              changeOnlyPayment = true;
            }
            return [
              fromActions.updatePaymentMethodSuccess({
                changeOnlyPayment,
                payment_method_id,
              }),
              fromActions.loadRequest({
                reservationId,
              }),
            ];
          }),
          catchError((error) => {
            this.errorHandler.handle(error);
            return of(fromActions.updatePaymentMethodFailure(error));
          }),
        );
      }),
    ),
  );
  /** aggiorniamo lo status della prenotazione senza reload ma aggiorniamo lo state al success */
  updateStatus$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.updateStatusRequest),
      exhaustMap(({ data }) => {
        return this.dataService.updateStatus(data).pipe(
          map((_) => {
            this.notifications.push({
              title: this.translate.instant('done'),
              content: this.translate.instant('notifications.update_success', {
                param: this.translate.instant('status'),
              }),
              type: 'success',
            });
            const { status, tabIndex, expiration_date, availability_option } =
              data;
            return fromActions.updateStatusSuccess({
              tabIndex,
              status,
              expiration_date,
              availability_option,
            });
          }),
          catchError((error) => {
            this.errorHandler.handle(error);
            return of(fromActions.updateStatusFailure(error));
          }),
        );
      }),
    ),
  );

  updateReservationDetails$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.updateReservationDetailsRequest),
      exhaustMap(({ reservationData, reservationId }) => {
        return this.dataService
          .updateReservationDetails(reservationId, reservationData)
          .pipe(
            map((_) => {
              this.notifications.push({
                title: this.translate.instant('done'),
                content: this.translate.instant(
                  'notifications.update_success',
                  {
                    param: this.translate.instant('reservation'),
                  },
                ),
                type: 'success',
              });
              return fromActions.updateReservationDetailsSuccess({
                reservationData,
              });
            }),
            catchError((error) => {
              this.errorHandler.handle(error);
              return of(fromActions.updateReservationDetailsFailure(error));
            }),
          );
      }),
    ),
  );

  updateBookerCompany$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.updateBookerCompanyRequest),
      flatMap(({ type, ...payload }) => {
        const { reservation_id } = payload;
        return this.dataService
          .updateReservationDetails(reservation_id, payload)
          .pipe(
            map(({ data, meta }: IResponseSuccess) => {
              if (meta.status === 'warning' && meta.confirm_required) {
                return fromActions.updateBookerCompanyWarning({
                  newPrice: get(data, 'total', 0),
                  requestPayload: payload,
                });
              }
              this.notifications.push({
                title: this.translate.instant('done'),
                content: this.translate.instant(
                  'notifications.update_success',
                  {
                    param: this.translate.instant('reservation'),
                  },
                ),
                type: 'success',
              });
              return fromActions.updateBookerCompanySuccess();
            }),
            catchError((error) => {
              this.errorHandler.handle(error);
              return of(fromActions.updateBookerCompanyFailure(error));
            }),
          );
      }),
    ),
  );

  updateCustomer$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.updateCustomerRequest),
      exhaustMap(({ customerData, reservationId, tabIndex }) => {
        const { language, ...dataRequest } = customerData;
        return this.dataService.updateCustomer(reservationId, dataRequest).pipe(
          map((_) => {
            this.notifications.push({
              title: this.translate.instant('done'),
              content: this.translate.instant('notifications.update_success', {
                param: this.translate.instant('customer'),
              }),
              type: 'success',
            });
            return fromActions.updateCustomerSuccess({
              tabIndex,
              customerData: { language },
            });
          }),
          catchError((error) => {
            this.errorHandler.handle(error);
            return of(fromActions.updateCustomerFailure(error));
          }),
        );
      }),
    ),
  );

  splitReservation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.splitReservationRequest),
      withLatestFrom(this.store),
      flatMap(([dataSplit, store]) => {
        const { data, onSuccess, onFailure } = dataSplit;

        const { id: reservationId } =
          store.reservationDetailsCustomStore.reservationDetails;

        return this.dataService.splitReservation(data).pipe(
          mergeMap((response: IResponseSuccess) => {
            if (onSuccess) {
              onSuccess();
            }

            if (!this.errorHandler.handleWarnings(response)) {
              this.notifications.push({
                title: this.translate.instant('done'),
                content: this.translate.instant(
                  'notifications.update_success',
                  {
                    param: this.translate.instant('reservation'),
                  },
                ),
                type: 'success',
              });
            }

            return [
              fromActions.splitReservationSuccess(),
              fromActions.loadRequest({ reservationId }),
            ];
          }),
          catchError((error) => {
            if (onFailure) {
              onFailure();
            }

            this.errorHandler.handle(error);
            return of(fromActions.splitReservationFailure(error));
          }),
        );
      }),
    ),
  );

  /** Effettuo il reload della prenotazione  */
  updateCheckinCheckoutDate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.updateCheckinCheckoutDateRequest),
      exhaustMap((payload) => {
        return this.dataService.updateCheckInOutDate(payload).pipe(
          switchMap((response: IResponseSuccess) => {
            const warnings: string[] = get(response, 'meta.warnings', []);
            const confirm_required = get(response, 'meta.confirm_required');

            if (confirm_required) {
              return [
                fromActions.updateCheckinCheckoutDateWarnings({
                  payload,
                  newData: { ...response.data[0], warnings },
                }),
                fromActions.updateCheckinCheckoutDateFailure({
                  error: new Error('Estimate required'),
                }),
              ];
            }

            warnings?.forEach((message) => {
              if (message) {
                this.notifications.push({
                  title: this.translate.instant('warning'),
                  content: message,
                  type: 'warning',
                });
              }
            });

            if (!warnings.length) {
              this.notifications.push({
                title: this.translate.instant('done'),
                content: this.translate.instant(
                  'notifications.update_success',
                  {
                    param: this.translate.instant('date'),
                  },
                ),
                type: 'success',
              });
            }

            return [
              fromActions.updateCheckinCheckoutDateSuccess(),
              fromActions.loadRequest({ reservationId: payload.reservationId }),
            ];
          }),
          catchError((error) => {
            this.errorHandler.handle(error);
            return of(fromActions.updateCheckinCheckoutDateFailure({ error }));
          }),
        );
      }),
    ),
  );

  calculateCityTax$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.calculateCityTaxRequest),
      switchMap(({ reservationId }) =>
        this.dataService.calculateCityTax(reservationId).pipe(
          map((response: IResponseSuccess) => {
            const { warnings } = response.meta;
            if (warnings) {
              warnings.forEach((warning) =>
                this.notifications.warning(warning),
              );
            } else {
              this.notifications.success('city_tax_was_calculated');
            }
            return fromActions.calculateCityTaxSuccess();
          }),
          catchError((error) => {
            this.errorHandler.handle(error);
            return of(fromActions.calculateCityTaxFailure(error));
          }),
        ),
      ),
    ),
  );

  createScaConfirmationRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.createScaConfirmationRequestRequest),
      switchMap(({ request }) =>
        this.dataService.createScaConfirmationRequest(request).pipe(
          map((response: IResponseSuccess<ScaConfirmationRequest>) => {
            if (request?.send_via_email) {
              this.notifications.done('sca_confirmation_request_created');
            }

            return fromActions.createScaConfirmationRequestSuccess({
              scaConfirmationRequest: response.data[0],
            });
          }),
          catchError((error) => {
            this.errorHandler.handle(error);
            return of(fromActions.createScaConfirmationRequestFailure(error));
          }),
        ),
      ),
    ),
  );

  setHousekeeperUsers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.setHousekeeperUsersRequest),
      switchMap(({ request, tabIndex }) =>
        this.housekeeperDashboardService.setRecordUsers(request).pipe(
          map(() => {
            this.notifications.success('notifications.generic_success');
            return fromActions.setHousekeeperUsersSuccess({
              request,
              tabIndex,
            });
          }),
          catchError((error) => {
            this.errorHandler.handle(error);
            return of(fromActions.setHousekeeperUsersFailure(error));
          }),
        ),
      ),
    ),
  );

  setHousekeeperRecord$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.setHousekeeperRecordRequest),
      switchMap(({ request, tabIndex }) =>
        this.housekeeperDashboardService.setRecord(request).pipe(
          map(() => {
            this.notifications.success('notifications.generic_success');
            return fromActions.setHousekeeperRecordSuccess({
              request,
              tabIndex,
            });
          }),
          catchError((error) => {
            this.errorHandler.handle(error);
            return of(fromActions.setHousekeeperRecordFailure(error));
          }),
        ),
      ),
    ),
  );

  resetCityTax$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.resetCityTaxRequest),
      switchMap(({ reservationId }) =>
        this.dataService.resetCityTax(reservationId).pipe(
          map((response: IResponseSuccess) => {
            const { warnings } = response.meta;
            if (warnings) {
              warnings.forEach((warning) =>
                this.notifications.warning(warning),
              );
            } else {
              this.notifications.success('city_tax_was_resetted');
            }
            return fromActions.resetCityTaxSuccess();
          }),
          catchError((error) => {
            this.errorHandler.handle(error);
            return of(fromActions.resetCityTaxFailure(error));
          }),
        ),
      ),
    ),
  );

  setKeepAvailability$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.setKeepAvailabilityRequest),
      exhaustMap(({ reservation_id, keep_availability }) => {
        return this.dataService
          .setKeepAvailability(keep_availability, reservation_id)
          .pipe(
            map(() => {
              this.notifications.push({
                title: this.translate.instant('done'),
                content: this.translate.instant(
                  'notifications.update_success',
                  {
                    param: this.translate.instant('reservation'),
                  },
                ),
                type: 'success',
              });
              return fromActions.setKeepAvailabilitySuccess({
                keep_availability,
              });
            }),
            catchError((error) => {
              this.errorHandler.handle(error);
              return of(fromActions.setKeepAvailabilityFailure(error));
            }),
          );
      }),
    ),
  );

  loadMedias$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.loadMediaRequest),
      withLatestFrom(this.store),
      exhaustMap(([{ accommodationIds }, store]) => {
        if (store.reservationDetailsCustomStore.loadMediaCompleted) {
          return of(fromActions.loadMediaFailure({ error: new Error() }));
        }
        return this.dataService.loadMedias(accommodationIds).pipe(
          map((response: any) => {
            return fromActions.loadMediaSuccess({
              medias: response,
            });
          }),
          catchError((error) => {
            //this.errorHandler.handle(error);
            return of(fromActions.loadMediaFailure(error));
          }),
        );
      }),
    ),
  );

  loadLogs$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.loadLogsRequest),
      exhaustMap(({ reservationId }) => {
        return this.dataService.loadLogs(reservationId).pipe(
          map((response: any) => {
            return fromActions.loadLogsSuccess({
              logsData: response.data,
            });
          }),
          catchError((error) => {
            this.errorHandler.handle(error);
            return of(fromActions.loadLogsFailure(error));
          }),
        );
      }),
    ),
  );

  loadWarnings$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.loadWarningsRequest),
      exhaustMap(({ reservationId }) => {
        return this.dataService.loadWarnings(reservationId).pipe(
          map((response: IResponseSuccess<ReservationWarning[]>) => {
            return fromActions.loadWarningsSuccess({
              warnings: response.data,
            });
          }),
          catchError((error) => {
            this.errorHandler.handle(error);
            return of(fromActions.loadWarningsFailure(error));
          }),
        );
      }),
    ),
  );

  attachCustomerReservation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.attachGuestToReservationRequest),
      switchMap(({ data, actionType, force_operation, hooks }) => {
        return this.dataService
          .attachCustomerDetails(data, actionType, !!force_operation)
          .pipe(
            map((resp) => {
              return {
                ...resp,
                data,
              };
            }),
            effectHooks(hooks),
            map((response: IResponseSuccess) => {
              const { warnings, confirm_required } = response.meta;
              if (warnings && warnings[0]) {
                this.notifications.warning(warnings[0]);

                if (confirm_required) {
                  this.warningConfirmModalService.open({
                    message: warnings[0],
                    action: fromActions.attachGuestToReservationRequest({
                      data,
                      actionType,
                      force_operation: true,
                      hooks,
                    }),
                  });

                  return fromActions.attachGuestToReservationFailure({
                    error: warnings[0],
                  });
                }
              } else {
                this.notifications.updateSuccess('customers');
              }

              return fromActions.attachGuestToReservationSuccess({ data });
            }),
            catchError((error: any) => {
              this.errorHandler.handle(error);
              return of(fromActions.attachGuestToReservationFailure({ error }));
            }),
          );
      }),
    ),
  );
  detachCustomerReservation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.detachGuestToReservationRequest),
      switchMap(({ data, force_operation, onlyAttach }) => {
        return this.dataService
          .attachCustomerDetails(data, 'delete', !!force_operation)
          .pipe(
            map((response: IResponseSuccess) => {
              const { warnings, confirm_required } = response.meta;
              if (warnings && warnings[0]) {
                this.notifications.warning(warnings[0]);

                if (confirm_required) {
                  this.warningConfirmModalService.open({
                    message: warnings[0],
                    action: fromActions.detachGuestToReservationRequest({
                      data,
                      force_operation: true,
                    }),
                  });

                  return fromActions.detachGuestToReservationFailure({
                    error: warnings[0],
                  });
                }
              } else {
                this.notifications.updateSuccess('customers');
              }
              return fromActions.detachGuestToReservationSuccess({
                data,
                onlyAttach,
              });
            }),
            catchError((error: any) => {
              this.errorHandler.handle(error);
              return of(fromActions.detachGuestToReservationFailure({ error }));
            }),
          );
      }),
    ),
  );

  setGuestCheckinCheckoutRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.setGuestCheckinCheckoutRequest),
      switchMap(({ res_acc_room_guest_id, checkin, checkout }) => {
        return this.dataService
          .updateCustomerCheckinOut(res_acc_room_guest_id, checkin, checkout)
          .pipe(
            map(() => {
              this.notifications.updateSuccess('customers');
              return fromActions.setGuestCheckinCheckoutSuccess();
            }),
            catchError((error: any) => {
              this.errorHandler.handle(error);
              return of(fromActions.setGuestCheckinCheckoutFailure({ error }));
            }),
          );
      }),
    ),
  );

  setEntireReservationKeepAccommodation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.setEntireReservationsKeepAccommodationRequest),
      switchMap((params) => {
        return this.dataService
          .setEntireReservationKeepAccommodation(params)
          .pipe(
            map(() => {
              this.notifications.updateSuccess('reservation');
              return fromActions.setEntireReservationsKeepAccommodationSuccess(
                params,
              );
            }),
            catchError((error: any) => {
              this.errorHandler.handle(error);
              return of(
                fromActions.setEntireReservationsKeepAccommodationFailure({
                  error,
                }),
              );
            }),
          );
      }),
    ),
  );

  setKeepAccommodation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.setKeepAccommodationRequest),
      switchMap((params) => {
        return this.dataService.setKeepAccommodation(params).pipe(
          map(() => {
            this.notifications.updateSuccess('reservation');
            return fromActions.setKeepAccommodationSuccess(params);
          }),
          catchError((error: any) => {
            this.errorHandler.handle(error);
            return of(fromActions.setKeepAccommodationFailure({ error }));
          }),
        );
      }),
    ),
  );

  setCityTaxReportExclusion$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.setCityTaxReportExclusionRequest),
      switchMap(({ type, ...request }) => {
        return this.dataService.setCityTaxReportExclusion(request).pipe(
          map(() => {
            this.notifications.updateSuccess('reservation');
            return fromActions.setCityTaxReportExclusionSuccess(request);
          }),
          catchError((error: any) => {
            this.errorHandler.handle(error);
            return of(fromActions.setCityTaxReportExclusionFailure({ error }));
          }),
        );
      }),
    ),
  );

  addReservationAccommodation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.addReservationAccommodationRequest),
      switchMap(({ data, onSuccess, onFailure }) => {
        return this.dataService.addReservationAccommodation(data).pipe(
          effectHooks({ onSuccess, onFailure }),
          map(({ data: response }: IResponseSuccess) => {
            this.notifications.push({
              title: this.translate.instant('done'),
              content: this.translate.instant('notifications.update_success', {
                param: this.translate.instant('reservation'),
              }),
              type: 'success',
            });

            return fromActions.addReservationAccommodationSuccess({
              accommodation: response[0],
            });
          }),
          catchError((error: HttpErrorResponse) => {
            this.notifications.push({
              title: this.translate.instant('error'),
              content:
                error.error.message ||
                this.translate.instant('notifications.update_failure', {
                  param: this.translate.instant('reservation'),
                }),
              type: 'error',
            });
            return of(
              fromActions.addReservationAccommodationFailure({
                error,
              }),
            );
          }),
        );
      }),
    ),
  );

  changePriceRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.changePriceRequest),
      switchMap(({ data }) => {
        return this.dataService.changePrice(data).pipe(
          map(({ meta }: IResponseSuccess) => {
            if (meta.status === 'warning') {
              (meta.warnings || []).forEach((warning) =>
                this.notifications.warning(warning),
              );
            } else {
              this.notifications.push({
                title: this.translate.instant('done'),
                content: this.translate.instant(
                  'notifications.update_success',
                  {
                    param: this.translate.instant('price'),
                  },
                ),
                type: 'success',
              });
            }

            return fromActions.changePriceSuccess();
          }),
          catchError((error: any) => {
            this.errorHandler.handle(error);
            return of(fromActions.changePriceFailure({ error }));
          }),
        );
      }),
    ),
  );

  dailyUpdate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.dailyUpdateRequest),
      flatMap(({ data, onSuccess, onFailure }) => {
        return this.dataService.dailyUpdate(data).pipe(
          map(({ meta }: IResponseSuccess) => {
            if (onSuccess) {
              onSuccess();
            }

            if (meta.status === 'warning') {
              (meta.warnings || []).forEach((warning) =>
                this.notifications.warning(warning),
              );
            } else {
              this.notifications.push({
                title: this.translate.instant('done'),
                content: this.translate.instant(
                  'notifications.update_success',
                  {
                    param: this.translate.instant('reservation'),
                  },
                ),
                type: 'success',
              });
            }

            return fromActions.dailyUpdateSuccess();
          }),
          catchError((error: any) => {
            if (onFailure) {
              onFailure();
            }

            this.errorHandler.handle(error);
            return of(fromActions.dailyUpdateFailure({ error }));
          }),
        );
      }),
    ),
  );

  loadCreditCard$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.loadCreditCardRequest),
      switchMap(({ data }) =>
        this.dataService.showCreditCard(data).pipe(
          map((response: IResponseSuccess) =>
            fromActions.loadCreditCardSuccess({
              data: response.data[0],
            }),
          ),
          catchError((error: any) => {
            this.errorHandler.handle(error);

            return of(
              fromActions.loadCreditCardFailure({
                error,
              }),
            );
          }),
        ),
      ),
    ),
  );

  updateReservationAccommodationBedTypeRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.updateReservationAccommodationBedTypeRequest),
      switchMap(
        ({
          reservationAccommodationId,
          bedTypeCombinationId,
          disableNotify,
          tabIndex,
        }) =>
          this.dataService
            .updateReservationAccommodationBedType(
              reservationAccommodationId,
              bedTypeCombinationId,
            )
            .pipe(
              mergeMap(() => {
                if (!disableNotify) {
                  this.notifications.push({
                    title: this.translate.instant('done'),
                    content: this.translate.instant(
                      'notifications.update_success',
                      {
                        param: this.translate.instant('preparation'),
                      },
                    ),
                    type: 'success',
                  });
                }
                return [
                  fromActions.updateReservationAccommodationBedTypeSuccess(),
                  fromActions.updateTab({
                    tabIndex,
                    data: {
                      summary: {
                        accommodation_bed_type_combination_id:
                          bedTypeCombinationId,
                      },
                    },
                  }),
                ];
              }),
              catchError((error: any) => {
                this.errorHandler.handle(error);

                return of(
                  fromActions.updateReservationAccommodationBedTypeFailure({
                    error,
                  }),
                );
              }),
            ),
      ),
    ),
  );
}
